import React, { FunctionComponent } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { ContentfulAttributionTabletalkQuery } from "graphql/types"
import { Attribution } from "@components/attribution/attribution"
import buildImageUrl from "@utils/imageUrl"

interface TabletalkAttributionProps {
  className?: string
}

export const TabletalkAttribution: FunctionComponent<
  TabletalkAttributionProps
> = ({ className, children }) => {
  const data: ContentfulAttributionTabletalkQuery = useStaticQuery(graphql`
    query ContentfulAttributionTabletalk {
      contentfulAttributionTabletalk {
        buttonText
        buttonLink
        body {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        image {
          file {
            url
          }
        }
      }
    }
  `)

  if (!data) return null
  const details = data?.contentfulAttributionTabletalk

  const imageUrl = buildImageUrl(details?.image?.file?.url || "", {
    width: 360
  })

  return (
    <Attribution
      className={className}
      imageSrc={imageUrl}
      body={details?.body?.childMarkdownRemark?.rawMarkdownBody || ""}
      buttonLink={details?.buttonLink || ""}
      buttonText={details?.buttonText || ""}
    >
      {children}
    </Attribution>
  )
}
