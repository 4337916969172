import { IntlShape } from "react-intl"
import { ScriptureDetailsFragment } from "../../../../graphql/types"
import { Resource, RelatedResources } from "../../../types/resource"
import { PathPrefix } from "../../../core/constants"
import { DataWithRelatedArticles } from "../../articles/article-types"
import { mapRelatedArticlesToView } from "../../articles/mappers/related-articles-mapper"
import { getScriptureTranslationId } from "../scripture-utils"

const ARTICLE_LIMIT = 3

export const mapSourceScriptureArticlesToView = (
  data: DataWithRelatedArticles<ScriptureDetailsFragment>
): RelatedResources | null => {
  if (!data.article?.length) {
    return null
  }

  const articles: Resource[] = mapRelatedArticlesToView(
    data.article,
    ARTICLE_LIMIT
  )

  if (!data.slug) {
    data.slug = `${data.book?.toLowerCase()}_${data.chapter}`
  }

  return {
    labelId: "featured-scripture-more",
    title: (intl: IntlShape) =>
      `${intl.formatMessage({
        id: getScriptureTranslationId(data.book)
      })} ${data.chapter}`,
    link: `${PathPrefix.Scriptures}/${data.slug!}`,
    body: "",
    articles
  }
}
