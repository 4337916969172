import { RelatedArticleFragment } from "../../../../graphql/types"
import { Resource } from "../../../types/resource"

export const mapRelatedArticlesToView = (
  articles: RelatedArticleFragment[],
  limit: number
): Resource[] =>
  articles.slice(0, limit).map((article) => {
    let time
    let avatar = ""
    let teacherName = ""

    if (article.bodyCopy || article.extendedCopy) {
      time = article.extendedCopy
        ? article.extendedCopy.reduce((acc, cur) => {
            const time = cur?.bodyCopy?.childMarkdownRemark?.timeToRead || 0

            return time + acc
          }, 0)
        : article.bodyCopy?.childMarkdownRemark?.timeToRead || 0
    }

    const teachers = article?.teachers

    if (teachers && teachers.length > 0) {
      avatar = teachers[0]!.image?.fluid?.src || ""
      teacherName = `${teachers[0]!.shortName}`
    }

    return {
      title: article!.name!,
      subtitle: teacherName,
      type: "article",
      slug: article!.slug!,
      time,
      avatar,
      image: article?.headerImage?.fluid?.src || ""
    }
  })
