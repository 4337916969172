import React, { FunctionComponent } from "react"
import { RelatedResources } from "../../types/resource"
import { RelatedResourcesList } from "./related-resources-list"
import styles from "./related-resources.module.scss"

export interface RelatedResourcesProps {
  relatedResources: RelatedResources[]
  allowRecombee?: boolean
}

export const RelatedResourcesView: FunctionComponent<RelatedResourcesProps> = ({
  relatedResources
}) => (
  <div className={styles.relatedResourcesWrapper}>
    {relatedResources.map((resourcesList, index) => (
      <RelatedResourcesList key={index} relatedResources={resourcesList} />
    ))}
  </div>
)
