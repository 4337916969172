import React, { FunctionComponent } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { CardResource } from "../card/card-resource"
import { Carousel } from "../carousel/carousel"
import { Link } from "../link/link"
import { getStringTranslator } from "../../i18n/helpers"
import styles from "./related-resources-list.module.scss"
import { RecombeeRelatedResource } from "@hooks/useRecombee"
import { CardRecommendation } from "@components/recommendations/recommendation-card"
import { Markdown } from "@components/markdown/markdown"

export interface RelatedResourcesListProps {
  relatedResources: RecombeeRelatedResource
}

export const RelatedResourcesList: FunctionComponent<
  RelatedResourcesListProps
> = ({ relatedResources }) => {
  const intl = useIntl()
  const translateString = getStringTranslator(intl)
  const isRecommendations = Boolean(relatedResources?.content?.recomms.length)

  return (
    <div className={styles.relatedResources}>
      <div className={styles.textContent}>
        <div className={styles.description}>
          <p className={styles.label}>
            <FormattedMessage id={relatedResources.labelId} />
          </p>
          <h3 className={styles.title}>
            <Link to={relatedResources.link}>
              {translateString(relatedResources.title)}
            </Link>
          </h3>
          <Markdown content={relatedResources.body} className={styles.body} />
          {/*
            // TODO: uncomment FOLLOW button in release 2.0
            <Button
              variant="secondary"
              className={styles.button}
              size="xs"
              to={relatedResources.link}
            >
              <FormattedMessage id="featured-button-follow" />
            </Button>
          */}
        </div>
      </div>
      <div className={styles.carouselWrapper}>
        <Carousel className={styles.carousel}>
          {isRecommendations
            ? relatedResources?.content?.recomms.map((content) => (
                <CardRecommendation key={content.id} content={content.values} />
              ))
            : relatedResources?.articles?.map((article, index) => (
                <CardResource key={index} {...article} type={article.type} />
              ))}
        </Carousel>
      </div>
    </div>
  )
}
