import {
  DevotionalPageQuery,
  DevotionalDetailsFragment,
  ContentfulTeacher
} from "../../../../graphql/types"
import { BreadcrumbResource } from "../../../components/breadcrumbs/breadcrumbs"
import { PathPrefix } from "../../../core/constants"
import { DevotionalDetails } from "../devotional-types"
import { NavigationItem } from "../../../types/navigation"
import { mapTeachers } from "../../../core/mappers/map-teachers"
import {
  mapScriptureReference,
  ContentfulScriptureReference,
  getTranslateableScripture,
  ScriptureReference
} from "../../scriptures/mappers/scripture-reference-mapper"
import { mapMetadata } from "../../../core/mappers/map-metadata"
import { truncate } from "../../../core/utils"

export const mapNavigationItem = (
  data: Pick<
    DevotionalDetailsFragment,
    "name" | "primaryScriptureReference" | "slug"
  >
): NavigationItem => {
  return {
    title: data.name!,
    subtitle: data.primaryScriptureReference?.start?.book
      ? getTranslateableScripture(
          mapScriptureReference(
            data.primaryScriptureReference as ContentfulScriptureReference
          )
        )
      : "",
    link: `${PathPrefix.Devotional}/${data.slug!}`
  }
}

type nextOrPreviousDevotional = Pick<
  DevotionalDetailsFragment,
  "name" | "primaryScriptureReference" | "slug"
>

export const mapSourceDevotionalToView = (
  query: DevotionalPageQuery,
  nextDevotional: nextOrPreviousDevotional | null,
  previousDevotional: nextOrPreviousDevotional | null
): DevotionalDetails => {
  const details = query.contentfulDevotional!

  const topics: BreadcrumbResource[] = query.allContentfulTopic.nodes.map(
    (node) => ({
      label: node.name!,
      link: `${PathPrefix.Topics}/${node.slug}`
    })
  )

  const topic = details.primaryTopic!

  if (topic) {
    topics.push({
      label: topic.name!,
      link: `${PathPrefix.Topics}/${topic.slug}`
    })
  }

  const coramDeo = details.coramDeo?.childMarkdownRemark?.plainText
  const metadata = mapMetadata(
    details.metadata,
    {
      title:
        details.name?.trim() +
        " | Reformed Bible studies & Devotionals at Ligonier.org",
      description: coramDeo && truncate(coramDeo)
    },
    {
      title:
        details.pageTitle?.trim() +
        " | Reformed Bible Studies & Devotionals at Ligonier.org"
    }
  )

  const furtherStudy = details.furtherStudy
    ? details.furtherStudy.reduce<ScriptureReference[]>((acc, ref) => {
        if (ref?.start?.book) {
          const mappedReference = mapScriptureReference(
            ref as ContentfulScriptureReference
          )

          acc.push(mappedReference)
        }

        return acc
      }, [])
    : []

  const mappedTeachers = mapTeachers(
    (details.teachers || []) as Partial<ContentfulTeacher>[]
  )

  // if the devotional is authored by R.C. Sproul we don't show the attribution
  const showTabletalkAttribution = !mappedTeachers
    .map(({ slug }) => slug)
    .includes("rc-sproul")

  return {
    id: details.id,
    bodyCopy: details.bodyCopy?.childMarkdownRemark?.html || "",
    time: details.bodyCopy?.childMarkdownRemark?.timeToRead || "",
    slug: details.slug!,
    title: details.name || "",
    pageTitle: details.pageTitle || "",
    primaryTopic: details.primaryTopic,
    scriptureText:
      details.scriptureText?.childMarkdownRemark?.rawMarkdownBody || "",
    topics,
    formattedDate: details.formattedDate,
    datePublished: new Date(details.datePublished),
    showTabletalkAttribution,
    coramDeo: details.coramDeo?.childMarkdownRemark?.rawMarkdownBody || "",
    scriptureVerse:
      details.primaryScriptureReference &&
      details.primaryScriptureReference.start?.book
        ? mapScriptureReference(
            details.primaryScriptureReference as ContentfulScriptureReference
          )
        : null,
    furtherStudy,
    teachers: details.teachers ? mappedTeachers : [],
    previousDevotional: previousDevotional
      ? mapNavigationItem(previousDevotional)
      : null,
    nextDevotional: nextDevotional ? mapNavigationItem(nextDevotional) : null,
    metadata
  }
}
