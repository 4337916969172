import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import { DevotionalPageQuery } from "../../../../graphql/types"
import { PageProps } from "../../../types/page"
import { RelatedResources } from "../../../types/resource"
import { mapSourceDevotionalToView } from "../mappers/devotional-mapper"
import { DevotionalDetailsPage } from "../components/devotional-details-page/devotional-details-page"
import { Page } from "../../../components/page/page"
import { mapSourceScriptureArticlesToView } from "../../scriptures/mappers/scripture-articles-mapper"
import { DevotionalNode } from "../../../../gatsby/config/create-pages/types"
import {
  TopicWithArticles,
  ScriptureReferenceWithArticles
} from "../../../types/related-resources"
import { PageBreadcrumbs } from "../../../core/breadcrumbs"

export const DevotionalTemplate: FunctionComponent<
  PageProps<
    DevotionalPageQuery,
    {
      topicArticles: TopicWithArticles
      scriptureArticles: ScriptureReferenceWithArticles
      nextDevotional: DevotionalNode | null
      previousDevotional: DevotionalNode | null
    }
  >
> = ({ data, ...props }) => {
  const devotionalData = data?.contentfulDevotional
  const { metadata } = devotionalData || {}

  const { scriptureArticles, nextDevotional, previousDevotional } =
    props.pageContext.initialData!

  const devotional = mapSourceDevotionalToView(
    data!,
    nextDevotional,
    previousDevotional
  )

  const metadataOverrides = {
    title: metadata?.title || devotional.metadata.title,
    description: metadata?.description || devotional.metadata.description,
    openGraphImage:
      metadata?.image?.fixed?.src || devotional.metadata.openGraphImage
  }

  const scriptureResources = scriptureArticles
    ? mapSourceScriptureArticlesToView(scriptureArticles)
    : null

  const relatedResources: RelatedResources[] = []

  if (scriptureResources) {
    relatedResources.push(scriptureResources)
  }

  return (
    <Page
      {...props}
      metadata={{ ...devotional.metadata, ...metadataOverrides }}
      pageOptions={{
        headerVariant: "cream",
        breadcrumbs: [
          ...PageBreadcrumbs.Devotionals,
          {
            label: devotional.title,
            link: `${props.uri}`
          }
        ]
      }}
    >
      <DevotionalDetailsPage
        contentfulId={props.pageContext.contentfulId}
        devotional={devotional}
        relatedResources={relatedResources}
      />
    </Page>
  )
}

export default DevotionalTemplate

export const query = graphql`
  query DevotionalPage($id: String, $topicCodes: [String]) {
    contentfulDevotional(id: { eq: $id }) {
      ...DevotionalDetails
      id
      primaryScriptureIndexReference {
        book
        chapter
        slug
      }
    }
    allContentfulTopic(
      filter: { topicCode: { in: $topicCodes } }
      sort: { fields: topicCode }
    ) {
      ...TopicDetailsList
    }
  }
`
