import React, { FunctionComponent, Fragment } from "react"
import { RelatedResources } from "../../../../types/resource"
import { Navigation } from "../../../../components/navigation/navigation"
import { RelatedResourcesView } from "../../../../components/related-resources/related-resources"
import { DevotionalDetails } from "../../devotional-types"
import { DevotionalView } from "../devotional-view/devotional-view"
import styles from "./devotional-details-page.module.scss"
import { TabletalkAttribution } from "@features/tabletalk-attribution/tabletalk-attribution"
import { Recommendations } from "@components/recommendations/recommendations"
import recomStyles from "@components/recommendations/recommendations.module.scss"
import { PathPrefix } from "@core/constants"

export interface DevotionalDetailsPageProps {
  contentfulId?: string
  devotional: DevotionalDetails
  relatedResources: RelatedResources[]
}

export const DevotionalDetailsPage: FunctionComponent<
  DevotionalDetailsPageProps
> = ({ contentfulId, devotional, relatedResources }) => {
  const { teachers, primaryTopic } = devotional
  const teacher = teachers?.[0]

  return (
    <Fragment>
      <DevotionalView devotional={devotional} contentfulId={contentfulId} />
      <Navigation
        time={devotional.time}
        previous={devotional.previousDevotional}
        next={devotional.nextDevotional}
      />
      {devotional.showTabletalkAttribution && <TabletalkAttribution />}
      <div className={recomStyles.relatedResourcesWrapper}>
        {teacher?.slug && (
          <Recommendations
            recombeeConfig={{
              type: "teacher",
              slug: teacher.slug,
              idsToRemove: [contentfulId || ""],
              itemId: contentfulId
            }}
            title={teacher.name}
            overline="More from this teacher"
            body={teacher.shortBio}
            link={`${PathPrefix.Teachers}/${teacher.slug}`}
          />
        )}
        {primaryTopic?.slug && (
          <Recommendations
            recombeeConfig={{
              type: "topic",
              slug: primaryTopic.slug,
              idsToRemove: [contentfulId || ""],
              itemId: contentfulId
            }}
            title={primaryTopic.name || ""}
            overline="More on this topic"
            body={primaryTopic.description?.description}
            link={`${PathPrefix.Topics}/${primaryTopic.slug}`}
          />
        )}
        <div className={styles.relatedResources}>
          <RelatedResourcesView relatedResources={relatedResources} />
        </div>
      </div>
    </Fragment>
  )
}
